<template>
  <div>
    <p class="m-0 cursor-pointer text-primary" @click="openModalClientDetail()">
      {{ clientInformation.client_name }}
    </p>
    <modal-client-detail
      v-if="modalClientDetailC"
      @hideModal="modalClientDetailC = false"
      :client-information="clientInformation"
    />
  </div>
</template>

<script>
import ModalClientDetail from "@/views/ce-digital/sub-modules/financial/view/pending-payments/view/components/modal/ModalClientDetail.vue"
export default {
  // Necessary to pass the clientInformation object to the modal
  // client_account_id
  // memo
  // user_memo
  // date_memo
  // client_name
  props: {
    clientInformation: {
      type: Object,
      default: () => {},
    },
  },
  components:{
    ModalClientDetail
  },
  data() {
    return {
      modalClientDetailC: false,
    };
  },
  methods: {
    openModalClientDetail() {
      this.modalClientDetailC = true;
    },
  },
  mounted() {

  },
};
</script>

<style>
</style>