<template>
  <b-modal
    ref="modal-client-detail-ce-digital"
    :title="`Client Detail of ${clientInformation.client_name}`"
    size="xlg"
    title-class="h3 text-white font-weight-bolder"
    modal-class="modal-primary"
    header-class="text-uppercase"
    @hidden="hideModal"
    title-tag="h5"
    scrollable
    hide-footer
  >
    <b-tabs v-model="tabIndex" lazy>
      <b-tab :title-link-class="linkClass(0)" class="mr-2">
        <template #title>
          <span>Notes</span>
        </template>
        <b-card-text>
          <b-form-group label="" class="mt-2 mb-2">
            <div class="d-flex justify-content-between align-items-center">
              <label for="" style="font-size: 14px !important">Memo</label>
              <div>
                <template v-if="editMemo">
                  <feather-icon
                    icon="CheckIcon"
                    class="cursor-pointer ml-1 text-success hover"
                    size="20"
                    @click="saveeditmemo(clientInformation.client_account_id)"
                  />
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer ml-1 text-danger hover"
                    size="20"
                    @click="canceleditmemo"
                  />
                </template>
                <template v-else>
                  <feather-icon
                    icon="Edit3Icon"
                    class="cursor-pointer ml-1 text-primary hover"
                    size="20"
                    @click="editMemo = !editMemo"
                  />
                </template>
              </div>
            </div>
            <div>
              <b-textarea
                :disabled="!editMemo"
                v-model="clientInformation.memo"
                class="d-flex justify-content-between w-100"
              />
            </div>
            <div>
              <span style="color: #cccccc">
                Updated by:
                {{
                  clientInformation.user_memo == null
                    ? "-"
                    : clientInformation.user_memo
                }}
                /
                <span v-if="clientInformation.date_memo == null">-</span>
                <span v-else>{{
                  clientInformation.date_memo | myGlobalDay
                }}</span>
              </span>
            </div>
          </b-form-group>
          <client-notes :client="clientInformation" />
        </b-card-text>
      </b-tab>
      <b-tab :title-link-class="linkClass(1)">
        <template #title>
          <span>Report</span>
        </template>
        <b-card-text class="pl-1 pr-1">
          <Report
            :clientId="clientInformation.client_account_id"
            :isFromModal="true"
          />
        </b-card-text>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import Report from "@/views/commons/components/clients/dashboard/options/report-module/Report.vue";
import ClientNotes from "../ClientNotes.vue";
import ClientDashboardService from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services.js";
import moment from "moment";
export default {
  props: {
    clientInformation: {
      type: Object,
    },
  },
  components: {
    Report,
    ClientNotes,
  },
  data() {
    return {
      tabIndex: 0,
      temporalMemo: "",
      editMemo: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.toggleModal("modal-client-detail-ce-digital");
    this.temporalMemo = this.clientInformation.memo;
  },
  methods: {
    hideModal() {
      this.$emit("hideModal");
    },
    closeModal() {
      this.toggleModal("modal-client-detail-ce-digital");
    },
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return [
          "bg-info",
          "text-white",
          idx === 0 ? "rounded-top mr-1" : "rounded-top ml-1",
          this.bgTabsNavs,
        ];
      } else {
        return ["bg-light", "text-secondary", "rounded-top", this.bgTabsNavs];
      }
    },
    canceleditmemo() {
      this.editMemo = false;
      this.clientInformation.memo = this.temporalMemo;
    },
    async saveeditmemo(clientAccountId) {
      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        try {
          const params = {
            id: clientAccountId,
            user_id: this.currentUser.user_id,
            memo: this.clientInformation.memo,
          };
          const data = await ClientDashboardService.editMemo(params);

          if (data.status === 200) {
            this.editMemo = false;
            this.temporalMemo = this.clientInformation.memo;
            this.clientInformation.user_memo = this.currentUser.fullName;
            this.clientInformation.date_memo = moment().format(
              "YYYY-MM-DD HH:mm:ss"
            );
          }
        } catch (error) {
          console.log("error edit memo", error);
        }
      }
    },
  },
};
</script>
<style lang="scss">
.hover {
  transition: 0.2s ease-in-out;
}
.hover:hover {
  transform: scale(1.2);
}
</style>
