<template>
  <div>
    <header-slot />
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['appPaymentsMade'].refresh()"
    >
      <template #table>
        <b-table
          ref="appPaymentsMade"
          no-border-collapse
          class="position-relative"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          :items="appPaymentsMadeProvider"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(client_name)="data">
            <router-link
              :class="[textLink]"
              :to="{
                name: 'financial-clients-account',
                params: {
                  idClient: data.item.client_account_id,
                },
              }"
              target="_blank"
              >{{ data.item.client_name }}
            </router-link>
            <div>
              <status-account :account="data.item" />
            </div>
            <span> {{ data.item.account }}</span>
          </template>
          <template #cell(settlement_date)="data">
            {{ data.item.settlement_date | myGlobalWithHour }}
          </template>
          <template #cell(amount)="data">
            $ {{ data.item.amount | currency }}
          </template>
          <template #cell(seen)="data">
            <div class="d-flex justify-content-center">
              <feather-icon
                v-if="data.item.seen_at == null"
                v-b-tooltip.hover.top="'Mark as seen'"
                icon="EyeIcon"
                class="text-primary cursor-pointer"
                @click="markAsSeen(data.item)"
              />
              <feather-icon v-else icon="CheckIcon" class="text-success" />
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </div>
</template>

<script>
import AppPaymentsMadeService from "@/views/ce-digital/sub-modules/financial/view/app-payments-made/app-payments-made.service";
import AppPaymentsMadeFilters from "@/views/ce-digital/sub-modules/financial/view/app-payments-made/data/app-payments-made.filters";
import { mapGetters, mapMutations } from "vuex";
import ViewClientDetails from "@/views/ce-digital/sub-modules/financial/components/ViewClientDetails.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
export default {
  components: {
    ViewClientDetails,
    StatusAccount,
  },
  data() {
    return {
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      isBusy: false,
      totalRows: 0,
      sortBy: "created_at",
      sortDesc: true,
      items: [],
      filters: AppPaymentsMadeFilters,
      fields: [
        {
          key: "client_name",
          sortable: false,
          label: "Client",
          visible: true,
        },
        {
          key: "amount",
          sortable: false,
          label: "Amount paid",
          visible: true,
        },
        {
          key: "transaction_id",
          sortable: false,
          label: "Transaction id",
          visible: true,
        },
        {
          key: "settlement_date",
          sortable: true,
          label: "Created at",
          visible: true,
        },
        {
          key: "seen",
          sortable: false,
          label: "Seen",
          visible: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    ...mapMutations({
      countPending: "SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY",
    }),
    async appPaymentsMadeProvider(ctx) {
      const parameters = {
        text: this.filterPrincipal.model,
        dateFrom: this.filters[0].model,
        dateTo: this.filters[1].model,
        perPage: this.paginate.perPage,
        page: this.paginate.currentPage,
        orderBy: ctx.sortBy ? ctx.sortBy : "created_at",
        order: ctx.sortDesc ? "desc" : "asc",
      };
      const data = await AppPaymentsMadeService.getAppPaymentsMade(parameters);
      const data2 = await AppPaymentsMadeService.getNotSeenAppPayMade();
      const payload = {
        routeName: "ce-digital-app-payments-made",
        tag: data2.data > 99 ? "99+" : data2.data,
      };
      await this.countPending(payload);
      this.items = data.data.data;
      this.startPage = data.data.from;
      this.paginate.currentPage = data.data.current_page;
      this.paginate.perPage = data.data.per_page;
      this.totalRows = data.data.total;
      this.toPage = data.data.to;
      return this.items || [];
    },
    async markAsSeen(item) {
      item.seen_at = 1;
      await AppPaymentsMadeService.markAsSeenAppPayMade({
        userId: this.currentUser.user_id,
        transactionId: item.id,
      });
      const data = await AppPaymentsMadeService.getNotSeenAppPayMade();
      const payload = {
        routeName: "ce-digital-app-payments-made",
        tag: data.data > 99 ? "99+" : data.data,
      };
      await this.countPending(payload);
    },
  },
};
</script>

<style scoped>
</style>
