<template>
  <div>
    <div class="d-flex justify-content-between">
      <div style="width: 40%" class="d-flex justify-content-between">
        <span class="k-font-weight-bold font-medium-1">NOTES</span>
        <b-form-select
          v-model="year"
          :options="yearOptions"
          class="ml-1"
          @input="getNotesPerYear"
        />
      </div>
      <!-- <b-button variant="primary" @click="openAddNotesModal">
        <feather-icon icon="PlusIcon" />ADD NOTE
      </b-button> -->
    </div>
    <div class="ml-n1 mr-n1">
      <div class="d-flex flex-wrap">
        <div
          v-for="(month, index) in months"
          :key="index"
          class="m-1 card rounded card-width"
        >
          <div
            class="text-center text-white rounded-top bg-secondary"
            style="padding-top: 0.4rem; padding-bottom: 0.4rem"
          >
            <span class="font-small-3">{{ months[index] }}</span>
          </div>
          <div
            class="text-center rounded-bottom cursor-pointer"
            :class="isDarkSkin ? 'border-dark' : 'border-light'"
            style="padding-top: 0.4rem; padding-bottom: 0.4rem"
            @click="openModalAllNotes(index + 1)"
          >
            <span class="text-primary">{{ getNumberOfNotes(index) }}</span>
          </div>
        </div>
      </div>
    </div>
    <list-all-notes-modal
      v-if="openModalAllNotesController"
      :month="monthToSend"
      :year="year"
      :name-client="client.client_name"
      :id-account="client.client_account_id"
      @close="closeModalAllNotes"
    />
  </div>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import ListAllNotesModal from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ListAllNotesModal.vue";
import moment from "moment";
export default {
  props: {
    client: {
      type: Object,
    },
  },
  data() {
    return {
      openModalAllNotesController: false,
      monthToSend: null,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      notes: [],
      year: null,
      yearOptions: [],
    };
  },
  components:{
    ListAllNotesModal
  },
  methods: {
    openModalAllNotes(monthNumber) {
      this.monthToSend = monthNumber;
      this.openModalAllNotesController = true;
    },
    closeModalAllNotes() {
      this.openModalAllNotesController = false;
    },
    getNumberOfNotes(index) {
      switch (index) {
        case 0:
          return this.notes.jan;
        case 1:
          return this.notes.feb;
        case 2:
          return this.notes.mar;
        case 3:
          return this.notes.apr;
        case 4:
          return this.notes.may;
        case 5:
          return this.notes.jun;
        case 6:
          return this.notes.jul;
        case 7:
          return this.notes.agu;
        case 8:
          return this.notes.set;
        case 9:
          return this.notes.oct;
        case 10:
          return this.notes.nov;
        case 11:
          return this.notes.dec;
      }
    },
    async getNotesPerYear(year) {
      const data = await ClientsOptionsServices.getNotesYear({
        id: this.client.client_account_id,
        year,
      });
      if (data.status === 200) {
        this.notes = data.data;
      }
    },
    yearSelect() {
      for (let x = 2014; x <= moment().format("YYYY"); x++) {
        this.yearOptions.push({ value: x, text: x });
      }
    },
  },
  async created() {
    this.addPreloader();
    this.year = moment().format("YYYY");
    await this.getNotesPerYear(this.year), this.yearSelect();
    this.removePreloader();
  },
};
</script>

<style scoped>
.card-width {
  min-width: 7.6rem;
}
.mr-half {
  margin: 0 !important;
  margin-right: 0.5rem !important;
}
.ml-custom {
  margin-left: 0.65rem !important;
}
@media only screen and (min-width: 1900px) {
  .card-width {
    min-width: 13rem;
  }
}
</style>